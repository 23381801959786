/**
 * axios 封装
 * @author maybe
 */
import axios, {
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  AxiosPromise,
  Canceler,
} from "axios";
import { Toast } from "vant";
import store from '@/store'
import { getToken, setToken, getUtmSrc } from "@/utils/auth";
import router from "@/router";
import { HttpResultJson } from "@/types/http";
import { breakPromise } from ".";
import { addRequest, refreshToken } from "./refreshToken";

import { useUserStore } from "@/store/modules/user";


/**
 * 请求失败后的错误统一处理
 * @param {HttpResultJson} res 响应json
 */
const handleError = (res: HttpResultJson, response: AxiosResponse) => {
  const { code, msg } = res;
  switch (code) {
    // 没权限, 跳转测试
    case 401:
      // 终止Promise链
      return breakPromise(() => {
        Toast.fail({
          message: msg,
          onClose: () => {
            router.replace("/login");
          }
        });
      });
    // token过期
    case 40101:
      // 延续Promise链
      return new Promise((reslove, reject) => {
        // Toast.fail({
        //   message: msg,
        //   onClose: () => {
        //     router.replace("/login");
        //   }
        // });
        // 添加请求到队列
        addRequest(() => reslove(service(response.config)));
        // 刷新token
        refreshToken()
      })
    // token无效
    case 40102:
      // 终止Promise链
      return breakPromise(() => {
        Toast.fail({
          message: msg,
          onClose: () => {
            // 退出登录
            const userStore = useUserStore();
            userStore.logout();
            router.replace('/login')
          }
        });
      });
    default:
      break;
  }
  return Promise.reject(new Error(res.msg));
};

const baseURL = (import.meta.env.DEV && import.meta.env.VITE_PROXY) ? '/proxy' : import.meta.env.VITE_BASE_API;
// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  baseURL, // .env中配置的api前缀
  timeout: 20000, // request timeout
});
/**
 * 请求拦截器
 */
service.interceptors.request.use(
  (config) => {
    const token = getToken();
    const utm_src = getUtmSrc();
    //  每次请求前，如果存在token则在请求头中携带token
    token && (config.headers!.Authorization = token);
    utm_src && (config.headers!['TK-UTM-SRC'] = utm_src);
    return config;
  },
  (error) => {
    // do something with request error
    console.error("error:", error);
    return Promise.reject(error);
  }
);

/**
 * 自定义响应状态码枚举
 */
enum ResponseCode {
  success = 200
}

/**
 * 响应拦截器
 */
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const res = response.data;

    // const token = response.headers['Authorization'];
    // // 动态更新token
    // if (token) {
    //   setToken(token);
    // }
    if (res.code !== ResponseCode.success) {
      // 处理错误
      return handleError(res, response);
    } else {
      // 响应成功
      return Promise.resolve(res);
    }
  },
  (error: AxiosError) => {
    console.error("response error:" + error); // for debug
    Toast.fail({
      message: error.message,
      duration: 1.5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
